import {
  ArrowLongLeftIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  commaNumber,
  openNotificationWithIcon,
  validateNumberAbove,
} from "../../../utils/helper";
import { Alert, Button, Form, Input, Modal, message } from "antd";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { extendDirectLoanOffer } from "../../../api/loan/loan";
import Inputamount from "../../../components/inputamount/inputamount";

const Viewloandetails = () => {
  const [form] = Form.useForm();
  const { reference_code } = useParams();
  const stateDetails = useLocation()?.state;
  const navigate = useNavigate();
  const item = stateDetails?.item;

  const [showModal, setShowModal] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    reference_code: "",
    amount: "",
    duration: "",
  });

  useEffect(() => {
    if (
      stateDetails === null ||
      stateDetails === undefined ||
      item === null ||
      item === undefined ||
      stateDetails?.item?.reference_code === undefined ||
      stateDetails?.item?.reference_code !== reference_code
    ) {
      navigate("/dashboard/loan");
    }
  }, []);

  const onFinishFailed = (errorInfo: any) => {
    errorInfo.errorFields.forEach((item: any) => {
      setErrors((prevState) => ({
        ...prevState,
        [item.name[0]]: item.errors.length ? item.errors[0] : "",
      }));
    });
  };

  const updateField = (name: string, value?: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onFinish = async (values: any) => {
    const { amount, duration } = values;

    setError(null);

    setLoading(true);

    const newErrors = { ...errors };

    newErrors["amount"] = validateNumberAbove(amount, 0, "amount");

    newErrors["duration"] = validateNumberAbove(duration, 0, "duration");

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await extendDirectLoanOffer({
          ...values,
          reference_code: item?.reference_code,
        });

        const { status, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Extend Loan", res.data.message);

          setShowModal(false);
          // navigate("/dashboard/request-loan-success");
        } else {
          // openNotificationWithIcon(
          //   "warning",
          //   "Submit Loan Offer",
          //   res.data.message
          // );
          setError(res.data.message);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        // setLoadingServices(false);
        message.error("Something went wrong: Extend Loan");
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="mt-[3rem]">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            navigate("/dashboard/loans");
          }}
        >
          <ArrowLongLeftIcon className="w-5 h-5" />
          <p className="pl-1 font-poppins">Back</p>
        </div>

        <div className={"flex flex-col justify-center"} id="content-id">
          <div className="flex justify-center ">
            <img src="/img/logo/logo.png" alt="logo" className="w-24" />
          </div>

          <div className="flex items-baseline justify-between">
            <h1 className="mb-1 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
              Loan Details
            </h1>
          </div>

          <div className="pt-3 pm-16">
            <div>
              <p className={`mb-0 text-2xl font-semibold font-ibmplexsans`}>
                ₦ {commaNumber(parseFloat(item?.principal_amount).toFixed(2))}
              </p>
              <div>
                <p className="font-bold">
                  {item?.type} <span className={""}>at</span>{" "}
                  {moment(item?.added_timestamp).format("lll")}
                </p>
              </div>
            </div>

            <div className="mt-4">
              {/* <p className="mb-4 text-sm font-poppins">Details</p> */}

              <div className="p-4 mb-3 rounded-lg shadow-lg bg-appcolorlightgray">
                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins"> Status</p>
                  <p
                    className={`w-[60%] flex justify-end font-poppins ${
                      item?.status === "Failed" || item?.status === "Overdue"
                        ? "text-appcolorred"
                        : item?.status === "Successful" ||
                          item?.status === "Disbursed" ||
                          item?.status === "Approved" ||
                          item?.status === "Repaid"
                        ? "text-appcolorgreen"
                        : item?.status === "Processing"
                        ? "text-appcoloryellow"
                        : "text-appcolorblack"
                    } `}
                  >
                    {item?.status}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">
                    {" "}
                    Repayment Amount (Total)
                  </p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    ₦{commaNumber(item?.repayment_amount?.total)}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">
                    {" "}
                    Repayment Amount (Remaining)
                  </p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    ₦{commaNumber(item?.repayment_amount?.remaining)}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">
                    Repayment Date
                  </p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    {moment(item?.repayment_date).format("lll")}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">
                    {" "}
                    Principal
                  </p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    ₦{commaNumber(item?.principal_amount)}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">
                    {" "}
                    Installment
                  </p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    {item?.instalment}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins"> Rate</p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    {item?.rate}%
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">
                    {" "}
                    Duration
                  </p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    {item?.duration} days
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">Date</p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    {moment(item?.added_timestamp).format("lll")}
                  </p>
                </div>

                <div className="flex justify-between mb-4 font-poppins">
                  <p className="w-[40%] font-semibold font-poppins">Remark</p>
                  <p className="w-[60%] flex justify-end font-poppins">
                    {item?.remark ? item?.remark : "-"}
                  </p>
                </div>

                <div className="font-poppins">
                  <p className="font-semibold font-poppins">Reference</p>
                  {/* <div className="font-semibold">{item?.reference_code}</div> */}
                </div>

                <Alert
                  className="alert-plain"
                  // message={<p className="font-semibold">Reference</p>}
                  description={
                    <div>
                      <p>{item?.reference_code}</p>
                      <div className="mt-4 w-[fit-content]">
                        <CopyToClipboard
                          text={item?.reference_code}
                          onCopy={() => {
                            message.info("Reference code copied to clipboard");
                          }}
                        >
                          <div className="flex items-center p-1 px-2 text-sm bg-appcolorgray rounded-[0.5rem]">
                            <span className="pr-8">Copy</span>
                            <ClipboardDocumentIcon className="w-4 h-4 text-appcolorwhite" />
                          </div>
                        </CopyToClipboard>
                      </div>
                    </div>
                  }
                  type="info"
                />
              </div>
            </div>
          </div>
        </div>

        {showModal && (
          <Modal
            open={showModal}
            footer={null}
            onCancel={
              loading
                ? undefined
                : () => {
                    setShowModal(false);
                  }
            }
            onOk={
              loading
                ? undefined
                : () => {
                    setShowModal(false);
                  }
            }
            destroyOnClose
          >
            <div className="">
              <div>
                <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
                  Request Loan Extension
                </h1>
              </div>

              {error != null && (
                <div className="mb-3">
                  <Alert message={error} type="error" className="w-full" />
                </div>
              )}

              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Form.Item
                  validateStatus={
                    errors?.amount.length > 0 ? "error" : undefined
                  }
                  help={errors?.amount.length > 0 ? errors.amount : undefined}
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Amount is required",
                    },
                  ]}
                >
                  <Inputamount
                    name="amount"
                    updateField={updateField}
                    placeHolder="Enter amount"
                    currency="₦"
                    isWholeNumber={true}
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={
                    errors?.duration.length > 0 ? "error" : undefined
                  }
                  help={
                    errors?.duration.length > 0 ? errors.duration : undefined
                  }
                  label="Duration (days)"
                  name="duration"
                  rules={[
                    {
                      required: true,
                      message: "Duration is required",
                    },
                  ]}
                >
                  <Input placeholder="Duration in days" min="1" type="number" />
                </Form.Item>

                <div>
                  <Button
                    htmlType="submit"
                    className="mb-2 "
                    disabled={loading}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        )}

        <div className="pb-32">
          {item?.status === "Overdue" ||
            (item?.status === "Disbursed" && (
              <div className="flex justify-between ">
                <Button
                  htmlType="submit"
                  className="mt-4 mb-4 w-[45%] lg:auto md:auto "
                  onClick={() => {
                    navigate(`/dashboard/repay/${item?.reference_code}`);
                  }}
                >
                  Repay loan
                </Button>

                <Button
                  htmlType="submit"
                  className="mt-4 mb-4 w-[45%] lg:auto md:auto "
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Extend Loan
                </Button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Viewloandetails;
