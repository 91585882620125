import { http, httpNoAuth } from "..";
import {
	createBeneficiaryPayload,
  createVirtualAccountPayload,
  createWalletPayload,
  fundAccountPayload,
  fundWalletPayload,
  getBeneficiariesPayload,
  getLoanHistoryPayload,
  getSupportedBanksInstitutionsPayload,
  getSupportedBanksPayload,
  getVirtualAccountsPayload,
  getWalletTransactionsPayload,
  makeTransferPayload,
  payLoanPayload,
	removeBeneficiaryPayload,
} from "./wallet-interface";

export const getWalletsDetails = () => {
	return http.post("/wallet/manager/details", {});
};

export const createWallet = (data: createWalletPayload) => {
  return http.post("/wallet/manager/create", data);
};

export const fundWallet = (data: fundWalletPayload) => {
  return http.post("/wallet/fund", data);
};

export const getWalletTransaction = (data: getWalletTransactionsPayload) => {
  return http.post("/transactions/view", data);
};

export const getLoanBalance = () => {
  return http.post("/loan/balance", {});
};

export const getLoanHistory = (data: getLoanHistoryPayload) => {
  return http.post("/loan/history", data);
};

export const payLoan = (data: payLoanPayload) => {
  return http.post("/service/loan/payment", data);
};

export const getSupportedCurrencies = () => {
  return httpNoAuth.post("/wallet/supported-currencies", {});
};

export const getVirtualAccounts = (data: getVirtualAccountsPayload) => {
  return http.post("/service/bankaccount/ngn/details", data);
};

export const createVirtualAccount = (data: createVirtualAccountPayload) => {
  return http.post("/service/bankaccount/ngn/create-user-base", data);
};

export const getSupportedBanks = (data: getSupportedBanksPayload) => {
  return httpNoAuth.post("/service/bankaccount/ngn/supported-bank", data);
};

export const getSupportedBanksInstitutions = (
  data: getSupportedBanksInstitutionsPayload
) => {
  return httpNoAuth.post(
    "/wallet/transfer/external/ngn/recipient/institutions",
    data
  );
};

export const getBeneficiaries = (data: any) => {
	const { beneficiaryType, ...rest } = data;

	return http.post(
    `${
      beneficiaryType === "External"
        ? "/wallet/transfer/external/ngn/recipient/details"
        : "/wallet/transfer/internal/ngn/recipient/details"
    }`,
    {
      ...rest,
    }
  );
};

export const makeTransfer = (
  data: makeTransferPayload,
  type: "single-external-transfer" | "single-internal-transfer"
) => {
  return http.post(
    `${
      type === "single-external-transfer"
        ? "/wallet/transfer/external/ngn/initiate/single"
        : "/wallet/transfer/internal/ngn/initiate/single"
    }`,
    data
  );
};


export const createBeneficiary = (data: createBeneficiaryPayload) => {
  const { beneficiaryType, ...rest } = data;
  return http.post(
    `${
      beneficiaryType === "External"
        ? "/wallet/transfer/external/ngn/recipient/single/add"
        : "/wallet/transfer/internal/ngn/recipient/single/add"
    }`,
    {
      ...rest,
    }
  );
};


export const removeBeneficiary = (data: removeBeneficiaryPayload) => {
	const { beneficiaryType, ...rest } = data;

	return http.post(
    `${
      beneficiaryType === "External"
        ? "/wallet/transfer/recipient/external/ngn/single/remove"
        : "/wallet/transfer/internal/ngn/recipient/single/remove"
    }`,
    {
      ...rest,
    }
  );
};


export const fundAccount = (data: fundAccountPayload) => {
  return http.post("/wallet/fund/card", data);
};

